import React from "react";
import styled from "styled-components";
import {Helmet} from "react-helmet";
// import { Link } from "react-router-dom";
import back from "../../../assets/images/construction/back.svg"

const UnderConstruction = (props) => {
    return (
            <MainContainer>
                <Wrapper>
                    <TopSection>
                        <Logo href="/">
                            <Image src={require("../../../assets/images/construction/logo.png").default} />
                        </Logo>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>Kinfig</title>
                            <link rel="canonical" href="http://kinfig.com/" />
                        </Helmet>
                        {/* <TopRight>
                            <Login href="https://steyp.com/tech-schooling/?action=login">
                                <LoginText>Login / Subscribe</LoginText>
                                <TechText>to Tech Schooling</TechText>
                            </Login>
                            <User href="https://steyp.com/tech-schooling/?action=login">
                                <Image src={require("../../../assets/images/construction/person.svg").default} />
                            </User>
                        </TopRight> */}
                    </TopSection>

                    <MiddleContainer>
                        <ContentSection>
                            {/* <SectionImage>
                                <Image
                                    src={require("../../../assets/images/construction/dashboard-img.svg").default}
                                />
                            </SectionImage> */}
                            <SectionTitle>What is Kinfig?</SectionTitle>
                            <Description>
                                Kinfig is a family networking platform that allows to capture, post, share and invite special moments with our family members. Our vision is to
                                provide quality relationship between families even in this digitalized world.
                            </Description>
                            {/* <GoButton href="https://steyp.com/">Go to Tech Schooling</GoButton> */}
                        </ContentSection>
                        <ConstructSection>
                            <Construct>
                                <ConstructImage src={require("../../../assets/images/construction/under-construction.png").default} />
                            </Construct>
                            <ConstructTitle>Our Website is Under Construction</ConstructTitle>
                            <Contact>
                                <Phone>
                                    <PhoneTag href="tel:+918589998919">
                                        <Image
                                            src={require("../../../assets/images/construction/call.svg").default}
                                        />
                                    </PhoneTag>
                                    <Mob href="tel:+918589998919">+91 858 999 8919</Mob>
                                </Phone>
                                <Mail>
                                    <MailTag href="mailto:hello@kinfig.com">
                                        <Image
                                            src={require("../../../assets/images/construction/mail.svg").default}
                                        />
                                    </MailTag>
                                    <Email href="mailto:hello@kinfig.com">hello@kinfig.com</Email>
                                </Mail>
                            </Contact>
                            <Social>
                                <SocialButton
                                    target="blank"
                                    href="https://www.linkedin.com/company/kinfigapp/"
                                >
                                    <Image
                                        src={require("../../../assets/images/construction/linked.svg").default}
                                    />
                                </SocialButton>
                                <SocialButton target="blank" href="https://www.facebook.com/kinfigapp/">
                                    <Image src={require("../../../assets/images/construction/fb.svg").default} />
                                </SocialButton>
                                {/* <SocialButton target="blank" href="https://youtube.com/c/Talrop">
                                    <Image
                                        src={require("../../../assets/images/construction/youtube.svg").default}
                                    />
                                </SocialButton> */}
                                <SocialButton target="blank" href="https://www.instagram.com/kinfigapp/">
                                    <Image src={require("../../../assets/images/construction/insta.svg").default} />
                                </SocialButton>
                                <SocialButton target="blank" href="https://twitter.com/kinfigapp">
                                    <Image
                                        src={require("../../../assets/images/construction/twitter.svg").default}
                                    />
                                </SocialButton>
                            </Social>
                        </ConstructSection>
                    </MiddleContainer>
                </Wrapper>
            </MainContainer>
    );
};
export default UnderConstruction;

const Wrapper = styled.div`
    width: 90%;
    max-width: 1350px;
    margin: 0 auto;
`;
const MainContainer = styled.div`
    background-image: url(${back});
    background-size: cover;
    @media (max-width: 768px) {
        padding-bottom: 30px;
    }
`;
const TopSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0px 30px;
    @media (max-width: 1280px) {
        padding: 40px 0 30px;
    }
    @media (max-width: 980px) {
        padding: 40px 0;
    }
    @media (max-width: 768px) {
        padding: 30px 0;
    }
    @media (max-width: 640px) {
        position: relative;
    }
    @media (max-width: 360px) {
        padding: 20px 0;
    }
`;
const Logo = styled.a`
    width: 150px;
    @media (max-width: 768px) {
        width: 70px;
    }
    @media (max-width: 480px) {
        width: 70px;
    }
`;
const Image = styled.img`
    display: block;
    width: 100%;
`;
// const TopRight = styled.div`
//     display: flex;
//     align-items: center;
// `;
// const Login = styled.a`
//     display: flex;
//     align-items: center;
//     font-size: 16px;
//     // font-family: "baloo_paaji_2semibold";
//     color: #27317B;
//     border: 2px solid;
//     border-radius: 6px;
//     padding: 10px 20px;
//     @media (max-width: 980px) {
//         padding: 5px 15px;
//     }
//     @media (max-width: 768px) {
//         font-size: 15px;
//     }
//     @media (max-width: 640px) {
//         padding: 9px 50px 9px 15px;
//         border-radius: 25px;
//     }
//     @media (max-width: 360px) {
//         padding: 9px 45px 10px 15px;
//     }
// `;
// const LoginText = styled.p`
//     font-size: 16px;
//     color: #27317B;
//     // font-family: "baloo_paaji_2semibold";
//     margin-right: 5px;
//     @media (max-width: 640px) {
//         margin-right: 0;
//     }
//     @media (max-width: 360px) {
//         font-size: 15px;
//     }
// `;
// const TechText = styled.p`
//     font-size: 16px;
//     color: #27317B;
//     // font-family: "baloo_paaji_2semibold";
//     @media (max-width: 640px) {
//         display: none;
//     }
// `;
// const User = styled.a`
//     width: 50px;
//     margin-left: 20px;
//     @media (max-width: 980px) {
//         width: 40px;
//     }
//     @media (max-width: 640px) {
//         position: absolute;
//         right: 37px;
//         width: 35px;
//         margin-left: 0;
//     }
//     @media (max-width: 360px) {
//         right: 26px;
//     }
// `;
const MiddleContainer = styled.div`
    display: flex;
    // flex-wrap: wrap;
    // background: #f00;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
    @media (max-width: 640px) {
        flex-direction: column-reverse;
    }
`;
const ConstructSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-right: 100px;
    @media (max-width: 1440px) {
        margin-right: 50px;
    }
    @media (max-width: 1280px) {
        margin-right: 25px;
    }
    @media (max-width: 980px) {
        width: 50%;
        margin-right: 0;
    }
    @media (max-width: 768px) {
        width: 60%;
        margin-right: 0;
    }
`;
const Construct = styled.div`
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #f00;
    width: 75%;
    @media (max-width: 1280px) {
        width: 60%;
    }
    @media (max-width: 640px) {
        width: 100%;
    }
`;
const ConstructImage = styled.img`
    width: 500px;
    // height: 500px;
    object-fit: contain;
    // background: #f0f;
    @media (max-width: 1280px) {
        width: 400px;
    }
    @media (max-width: 640px) {
        width: 300px;
    }
`;
const ConstructTitle = styled.h2`
    text-align: center;
    line-height: 33px;
    font-size: 26px;
    // font-family: "baloo_paaji_2semibold";
    max-width: 350px;
    margin: 0 auto;
    color: #4b4b4b;
    @media (max-width: 1280px) {
        font-size: 23px;
    }
    @media (max-width: 768px) {
        font-size: 20px;
        max-width: 300px;
    }
    @media (max-width: 420px) {
        font-size: 16px;

        line-height: 25px;
    }
    @media (max-width: 360px) {
        font-size: 14px;

        line-height: 25px;
    }
`;
const Contact = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    @media (max-width: 980px) {
        display: block;
    }
    @media (max-width: 360px) {}
`;
const Phone = styled.div`
    display: flex;
    align-items: center;
    margin-right: 30px;
    @media (max-width: 980px) {
        margin-bottom: 15px;
        margin-right: 0;
    }
`;
// const PhoneNum = styled(Link)`
//     font-size: 19px;
// font-family: "baloo_paaji_2medium";
//     @media (max-width: 1280px) {
//         font-size: 16px;
// font-family: "baloo_paaji_2semibold";
//     }
// `;
// const PhoneImg = styled.div`
//     width: 40px;
//     margin-right: 10px;
//     @media (max-width: 980px) {
//         width: 30px;
//     }
// `;
// const MailImg = styled.div`
//     width: 40px;
//     margin-right: 10px;
//     @media (max-width: 980px) {
//         width: 30px;
//     }
// `;
const Mail = styled.div`
    display: flex;
    align-items: center;
`;
// const MailId = styled(Link)`
//     font-size: 19px;
// font-family: "baloo_paaji_2medium";
//     @media (max-width: 1280px) {
//         font-size: 16px;
// font-family: "baloo_paaji_2semibold";
//     }
// `;
const Social = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px dashed #27317B;
    padding-top: 30px;
    margin: 0 100px;
    @media (max-width: 980px) {
        padding-top: 20px;
        margin: 0 70px;
    }
    @media (max-width: 640px) {
        margin: 0;
        padding-bottom: 30px;
    }
`;
const SocialButton = styled.a`
    display: inline-block;
    width: 40px;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
    @media (max-width: 980px) {
        width: 30px;
    }
`;
const ContentSection = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 980px) {
        margin-right: 20px;
    }
    @media (max-width: 768px) {
        margin-top: 30px;
    }
    @media (max-width: 640px) {
        margin-bottom: 50px;
        width: 90%;
    }
`;
// const SectionImage = styled.div`
//     width: 90%;
//     @media (max-width: 1280px) {
//         width: 80%;
//     }
//     @media (max-width: 640px) {
//         margin-top: 20px;
//     }
// `;
const SectionTitle = styled.h3`
    font-size: 32px;
    // font-family: "baloo_paaji_2semibold";
    color: #27317B;
    margin-bottom: 10px;
    @media (max-width: 1280px) {
        font-size: 21px;
    }
    @media (max-width: 768px) {
        font-size: 19px;
    }
    @media (max-width: 360px) {
        font-size: 16px;
    }
`;
const Description = styled.p`
    font-size: 24px;
    max-width: 650px;
    line-height: 45px;
    color: #4b4b4b;
    margin: 0 auto;
    // font-family: "baloo_paaji_2medium";
    @media (max-width: 1280px) {
        font-size: 17px;
        line-height: 35px;
    }
    @media (max-width: 480px) {
        font-size: 15px;
        line-height: 30px;
    }
    @media (max-width: 360px) {
        font-size: 14px;
        line-height: 20px;
    }
`;
// const GoButton = styled.a`
//     display: inline-block;
//     font-size: 16px;
//     // font-family: "baloo_paaji_2medium";
//     background-color: #27317B;
//     color: #fff;
//     padding: 10px 30px;
//     border-radius: 6px;
//     margin-top: 25px;
//     @media (max-width: 768px) {
//         padding: 10px 25px;
//         font-size: 15px;
//     }
// `;
// const PhoneImage = styled.img`
//     display: block;
//     width: 100%;
//     margin-right: 10px;
// `;
const PhoneTag = styled.a`
    margin-right: 10px;
`;
const MailTag = styled.a`
    margin-right: 10px;
`;
const Mob = styled.a`
    text-decoration: none;
    color: #27317B;
    // @media (max-width: 768px) {
    //     font-size: 18px
    // }
    // @media (max-width: 420px) {
    //     font-size: 14px
    // }
    // @media (max-width: 360px) {
    //     font-size: 14px
    // }
`;
const Email = styled.a`
    text-decoration: none;
    color: #27317B;
`;